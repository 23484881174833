import { getSettingValueByKey, NarrowAction } from '@tager/web-modules';

import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import { SettingsItemType } from '@/typings/model';
import { prepareKeyWithLanguage } from '../utils/common';

function useSettingItem<K extends SettingsItemType['key']>(
  key: K,
  language?: string,
): NarrowAction<SettingsItemType, K>['value'] {
  const settingItemList = useTypedSelector(selectSettingItemList);
  const keyWithLanguage: any = language && language !== 'ru' ? prepareKeyWithLanguage(key, language) : key;

  return getSettingValueByKey(settingItemList, keyWithLanguage);
}

export default useSettingItem;
